/** @format */
import logo from "../../assets/logoHT.svg";
import logo_2 from "../../assets/LotusFlower.png";

const Footer = () => {
  return (
    <footer id="footer" className="">
      <div className="flex flex-col md:flex-row bg-[#6da4da] md:px-32 p-10 md:space-x-12 md:space-y-0 space-y-2 md:justify-around">
        <div className="space-y-2">
          <div className="flex space-x-4">
            <img
              src={logo}
              className="w-[80px] h-[80px] border-[1px] border-white"
            />
            <img
              src={logo_2}
              className="w-[80px] h-[80px] border-[1px] border-white"
            />
          </div>

          <p className="text-white align-text-bottom 2xl:text-[16px] text-[16px]">
            HOANG THANH CAPITAL & PARTNERS CO. LTD
          </p>
          <p className="text-white align-text-bottom text-[16px]">
            Copyright © 2024 Hoang Thanh ®
          </p>
        </div>

        <div className="space-y-2 text-white 2xl:text-[18px]">
          <p className="">Suite 6, Floor 1, Hoang Thanh Tower</p>
          <p className="">114 Mai Hac De Street</p>
          <p className="">Hai Ba Trung District, Hanoi (HO)</p>
          <p className="">Tel: (+84) 24 3926 4083 </p>
        </div>

        <div className="space-y-2 text-white 2xl:text-[18px]">
          <p className="">98-100-102 Le Lai Street</p>
          <p className="">Ben Thanh Ward, District 1</p>
          <p className="">Ho Chi Minh City, Vietnam</p>
          <p className="">Tel: (+84) 28 3931 1039 </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
