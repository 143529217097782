import React from "react";

import imgLogo from "../../assets/LotusFlower.png";

import imgMission from "../../assets/MVP/misson_1.jpg";
import imgVision from "../../assets/MVP/vision_bg.jpg";
import imgPurpose from "../../assets/MVP/purpose_bg.jpg";

import imgLogo1 from "../../assets/logoPartner/logoGoldmanSachs.png";
import imgLogo2 from "../../assets/logoPartner/logoMitsui.png";
import imgLogo3 from "../../assets/logoPartner/logoMizuho.png";
import imgLogo4 from "../../assets/logoPartner/logoSMBC.png";
import imgLogo5 from "../../assets/logoPartner/logoFWD-Insurance.png";

import CarouselCustomNavigation from "./Carousel/Carousel";

import imgAccountability from "../../assets/carousel_img/our_culture_Accounttability_1.jpg";
import imgAdaptability from "../../assets/carousel_img/v2_OurcultureAdaptability_1.jpg";
import imgCollaboration from "../../assets/carousel_img/Collaboration.jpg";
import imgCompassion from "../../assets/carousel_img/our_culture_ Compassion_1.jpg";
import imgDiligence from "../../assets/carousel_img/v2_OurcultureDiligence_1.jpg";
import imgFun from "../../assets/carousel_img/v2_OurcultureFun_1.jpg";
import imgInnovation from "../../assets/carousel_img/INNOVATION_1.jpg";
import imgSelfDevelopment from "../../assets/carousel_img/our_culture_Self_Development_1.jpg";
import imgTransparency from "../../assets/carousel_img/Our_culture_Transparency_1.jpg";
import imgWellBeing from "../../assets/carousel_img/v2_OurcultureWell-being_1.jpg";
import imgWillpower from "../../assets/carousel_img/Our_culture_Willpower_1.jpg";
import imgWisdom from "../../assets/carousel_img/Our_culture_Wisdom_1.jpg";
import videoHoangThanh from "../../assets/video/video.mp4";

import { useRef } from "react";
import { Header } from "./../../components";
import { useNavigate } from "react-router-dom";
import { path } from "framer-motion/client";

const Home = () => {
  const headerRef = useRef(null);
  const navigate = useNavigate();
  const scrollToAboutUs = () => {
    navigate(path.Landing);

    setTimeout(() => {
      const aboutus = document.getElementById("aboutus");
      if (aboutus) {
        aboutus.scrollIntoView({ behavior: "smooth" });
      }
    });
  };
  return (
    <div>
      <Header ref={headerRef} />
      <div className="video-container relative w-full h-[38vh] md:h-[100vh]">
        <video
          autoPlay
          loop
          muted
          playsInline
          className="w-full h-full object-fill"
        >
          <source src={videoHoangThanh} type="video/mp4" />
          Trình duyệt của bạn không hỗ trợ video.
        </video>

        <div className="overlay absolute top-0 left-0 w-full h-full bg-black bg-opacity-50"></div>

        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 px-[10vw] h-fit  ">
          <h2 className="text-[3vw] md:text-[4vw] lg:text-[2.5vw] text-white h-fit font-bold pb-[4vw] lg:pb-[2vw]">
            HOANG THANH GROUP
          </h2>
          <p className="text-[2vw] md:text-[3vw] lg:text-[1.2vw] text-white h-fit w-[60vw] pb-[4vw] lg:pb-[2vw]">
            Hoang Thanh Group is an investment holding company with M&A and principal investments as our core businesses. Through our portfolio companies our activities also extend to digital solutions consultancy & development, wholesale end-to-end logistics, real estate brokerage and development, hospitality, and architect & interior designs.
          </p>
          {/* <button
            className="text-[2.5vw] md:text-[1.5vw] lg:text-[1vw] bg-transparent border border-white text-white py-2 px-4 rounded h-fit w-fit hover:bg-[#6da4da] duration-500 ease-linear"
            onClick={() => {
              scrollToAboutUs();
            }}
          >
            Explore Our Culture
          </button> */}
        </div>
      </div>

      <div className="flex flex-col items-center gap-4 py-2 md:py-4">
        <div className="flex flex-col items-center">
          <img
            src={imgLogo}
            alt="Logo"
            className="w-[15vw] h-[15vw] object-cover "
          />
        </div>

        <p className="text-[2.2vw] md:text-[1.8vw] lg:text-[1.5vw] text-[#333333] text-center leading-tight">
          To live an excellent life.
        </p>
      </div>

      <div className="flex flex-wrap lg:flex-nowrap h-fit">
        {/* Vision */}
        <div className="relative w-full lg:w-1/3 h-[45vw] lg:h-[30vw] group cursor-pointer">
          <div className="relative w-full h-full">
            <img
              src={imgVision}
              alt="Mission"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black opacity-25"></div>
          </div>

          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center group-hover:hidden">
            <div className="text-white text-center flex flex-col justify-center items-center h-fit">
              <p className="font-bold text-[18vw] lg:text-[9vw] leading-none m-0 p-0 h-fit">
                V
              </p>
              <p className="font-bold text-[5vw] lg:text-[2.5vw] leading-none m-0 p-0 h-fit">
                VISION
              </p>
            </div>
          </div>

          <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-70 text-black flex flex-col items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 lg:p-[3vw] p-[5vw]">
            <p className="text-[3.5vw] lg:text-[2.25vw] font-bold mb-1 leading-tight h-fit pb-[2vw] md:pb-[0.5vw]">
              VISION
            </p>
            <p className="text-[2.2vw] lg:text-[1.1vw] leading-[1.75] h-fit break-words text-justify">
              Together we build a better world, a better universe. Where
              happiness comes to all beings and things. A place where
              development is sustainable and nature is protected.
            </p>
          </div>
        </div>

        {/* Mission */}
        <div className="relative w-full lg:w-1/3 h-[45vw] lg:h-[30vw] group cursor-pointer">
          <div className="relative w-full h-full">
            <img
              src={imgMission}
              alt="Mission"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black opacity-15"></div>
          </div>

          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center group-hover:hidden">
            <div className="text-white text-center flex flex-col justify-center items-center h-fit">
              <p className="font-bold text-[18vw] lg:text-[9vw] leading-none m-0 p-0 h-fit">
                M
              </p>
              <p className="font-bold text-[5vw] lg:text-[2.5vw] leading-none m-0 p-0 h-fit">
                MISSION
              </p>
            </div>
          </div>

          <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-70 text-black flex flex-col items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 lg:p-[3vw] p-[5vw]">
            <p className="text-[3.5vw] lg:text-[2.25vw] font-bold mb-1 leading-tight h-fit pb-[2vw] md:pb-[0.5vw]">
              MISSION
            </p>
            <p className="text-[2.2vw] lg:text-[1.1vw] leading-[1.75] h-fit break-words text-justify">
              We aspire to become a leading and lasting multi-industry corporation in Vietnam, a place where people are inspired to work and contribute based on three core principles: benefiting the environment, benefiting society, and benefiting ourselves.
            </p>
          </div>
        </div>

        {/* Purpose */}
        <div className="relative w-full lg:w-1/3 h-[45vw] lg:h-[30vw] group cursor-pointer">
          <div className="relative w-full h-full">
            <img
              src={imgPurpose}
              alt="Mission"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black opacity-15"></div>
          </div>

          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center group-hover:hidden">
            <div className="text-white text-center flex flex-col justify-center items-center h-fit">
              <p className="font-bold text-[18vw] lg:text-[9vw] leading-none m-0 p-0 h-fit">
                C
              </p>
              <p className="font-bold text-[5vw] lg:text-[2.5vw] leading-none m-0 p-0 h-fit">
                CORE VALUES
              </p>
            </div>
          </div>

          <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-70 text-black flex flex-col items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 lg:p-[3vw] p-[5vw]">
            <p className="text-[3.5vw] lg:text-[2.25vw] font-bold mb-1 leading-tight h-fit pb-[2vw] md:pb-[0.5vw]">
              CORE VALUES
            </p>
            <p className="text-[2.2vw] lg:text-[1.1vw] leading-[1.75] h-fit break-words text-justify">
              People-centered, the right person first, work follows. Every person at Hoang Thanh Group lives an excellent life, to practice and develop the 7 individual core values of Good Deeds, Hard Work, Ethics, Wisdom, Self-Driven, Gratitude, and Harmony.
            </p>
          </div>
        </div>
      </div>

      {/* <div className="flex flex-col items-center pt-[4vw] px-10 h-fit">
        <p className="text-[3.5vw] md:[3vw] lg:text-[2vw] text-[#333333] text-center leading-tight font-bold">
          RUN PARTNERS & CUSTOMERS
        </p>
        <p className="text-[2.2vw] md:[1.8vw] lg:text-[1.2vw] text-[#333333] text-center leading-tight py-[2vw]">
          Big brands, small business, new startup and individuals
        </p>
        <div className="flex flex-wrap justify-center gap-x-12 md:gap-x-16 gap-y-8">
          <img
            src={imgLogo1}
            alt="Goldman Sachs"
            className="h-10 md:h-16 w-fit"
          />
          <img src={imgLogo2} alt="Mitsui" className="h-10 md:h-16 w-fit" />
          <img src={imgLogo3} alt="Mizuho" className="h-10 md:h-16 w-fit" />
          <img src={imgLogo4} alt="SMBC" className="h-10 md:h-16 w-fit" />
          <img
            src={imgLogo5}
            alt="FWD Insurance"
            className="h-10 md:h-16 w-fit"
          />
        </div>
      </div> */}

      <div className="flex flex-col items-center py-[6vw] h-fit">
        {/* <Carousel>  */}
        <CarouselCustomNavigation />
        {/* </Carousel>  */}
      </div>

      {/* Grid */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gridTemplateRows: "repeat(5, calc((100vw / 3) * (3 / 5)))",
          width: "100%",
          height: "auto",
          boxSizing: "border-box",
        }}
        className="mb-14 pv:max-md:mb-8"
      >
        <div
          style={{
            position: "relative",
            backgroundColor: "#6BA4DF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={imgAccountability}
            alt="Accountability"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              opacity: 0.8,
            }}
          />
          <div className="absolute inset-0 bg-black opacity-30"></div>
          <div
            style={{
              position: "absolute",
              top: "20px",
              left: "20px",
              color: "#fff",
              fontSize: "2vw",
              fontWeight: "bold",
              height: "auto",
              width: "auto",
            }}
          >
            ACCOUNTABILITY
          </div>
        </div>

        <div
          style={{
            position: "relative",
            backgroundColor: "#6BA4DF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={imgAdaptability}
            alt="Adaptability"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              opacity: 0.8,
            }}
          />
          <div className="absolute inset-0 bg-black opacity-30"></div>
          <div
            style={{
              position: "absolute",
              top: "20px",
              left: "20px",
              color: "#fff",
              fontSize: "2vw",
              fontWeight: "bold",
              height: "auto",
              width: "auto",
            }}
          >
            ADAPTABILITY
          </div>
        </div>

        <div
          style={{
            position: "relative",
            backgroundColor: "#6BA4DF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={imgCollaboration}
            alt="Collaboration"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              opacity: 0.8,
            }}
          />
          <div className="absolute inset-0 bg-black opacity-30"></div>
          <div
            style={{
              position: "absolute",
              top: "20px",
              left: "20px",
              color: "#fff",
              fontSize: "2vw",
              fontWeight: "bold",
              height: "auto",
              width: "auto",
            }}
          >
            COLLABORATION
          </div>
        </div>

        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            backgroundColor: "#ffffff",
            height: "100%",
            display: "flex",
          }}
        >
          <p
            style={{
              height: "auto",
              fontSize: "3vw",
              margin: 0,
            }}
          >
            "Pioneering
          </p>
        </div>

        <div
          style={{
            position: "relative",
            backgroundColor: "#6BA4DF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={imgCompassion}
            alt="Compassion"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "bottom",
              opacity: 0.8,
            }}
          />
          <div className="absolute inset-0 bg-black opacity-30"></div>
          <div
            style={{
              position: "absolute",
              top: "20px",
              left: "20px",
              color: "#fff",
              fontSize: "2vw",
              fontWeight: "bold",
              height: "auto",
              width: "auto",
            }}
          >
            COMPASSION
          </div>
        </div>

        <div
          style={{
            position: "relative",
            backgroundColor: "#6BA4DF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={imgDiligence}
            alt="Diligence"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              opacity: 0.8,
            }}
          />
          <div className="absolute inset-0 bg-black opacity-30"></div>
          <div
            style={{
              position: "absolute",
              top: "20px",
              left: "20px",
              color: "#fff",
              fontSize: "2vw",
              fontWeight: "bold",
              height: "auto",
              width: "auto",
            }}
          >
            DILIGENCE
          </div>
        </div>

        <div
          style={{
            position: "relative",
            backgroundColor: "#6BA4DF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={imgFun}
            alt="Fun"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              opacity: 0.8,
            }}
          />
          <div className="absolute inset-0 bg-black opacity-30"></div>
          <div
            style={{
              position: "absolute",
              top: "20px",
              left: "20px",
              color: "#fff",
              fontSize: "2vw",
              fontWeight: "bold",
              height: "auto",
              width: "auto",
            }}
          >
            FUN
          </div>
        </div>

        <div
          style={{
            position: "relative",
            backgroundColor: "#6BA4DF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={imgInnovation}
            alt="Innovation"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              opacity: 0.8,
            }}
          />
          <div className="absolute inset-0 bg-black opacity-30"></div>
          <div
            style={{
              position: "absolute",
              top: "20px",
              left: "20px",
              color: "#fff",
              fontSize: "2vw",
              fontWeight: "bold",
              height: "auto",
              width: "auto",
            }}
          >
            INNOVATION
          </div>
        </div>

        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            backgroundColor: "#ffffff",
            height: "100%",
            display: "flex",
          }}
        >
          <p
            style={{
              height: "auto",
              fontSize: "3vw",
              margin: 0,
            }}
          >
            a legacy of
          </p>
        </div>

        <div
          style={{
            position: "relative",
            backgroundColor: "#6BA4DF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={imgSelfDevelopment}
            alt="Self Development"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              opacity: 0.8,
            }}
          />
          <div className="absolute inset-0 bg-black opacity-30"></div>
          <div
            style={{
              position: "absolute",
              top: "20px",
              left: "20px",
              color: "#fff",
              fontSize: "2vw",
              fontWeight: "bold",
              height: "auto",
              width: "auto",
            }}
          >
            SELF-DEVELOPMENT
          </div>
        </div>

        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            backgroundColor: "#ffffff",
            height: "100%",
            display: "flex",
          }}
        >
          <p
            style={{
              height: "auto",
              fontSize: "3vw",
              margin: 0,
            }}
          >
            excellence"
          </p>
        </div>

        <div
          style={{
            position: "relative",
            backgroundColor: "#6BA4DF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={imgTransparency}
            alt="Transparency"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              opacity: 0.8,
            }}
          />
          <div className="absolute inset-0 bg-black opacity-20"></div>
          <div
            style={{
              position: "absolute",
              top: "20px",
              left: "20px",
              color: "#fff",
              fontSize: "2vw",
              fontWeight: "bold",
              height: "auto",
              width: "auto",
            }}
          >
            TRANSPARENCY
          </div>
        </div>

        <div
          style={{
            position: "relative",
            backgroundColor: "#6BA4DF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={imgWellBeing}
            alt="Well Being"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              opacity: 0.8,
            }}
          />
          <div className="absolute inset-0 bg-black opacity-30"></div>
          <div
            style={{
              position: "absolute",
              top: "20px",
              left: "20px",
              color: "#fff",
              fontSize: "2vw",
              fontWeight: "bold",
              height: "auto",
              width: "auto",
            }}
          >
            WELL-BEING
          </div>
        </div>

        <div
          style={{
            position: "relative",
            backgroundColor: "#6BA4DF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={imgWillpower}
            alt="Willpower"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              opacity: 0.8,
            }}
          />
          <div className="absolute inset-0 bg-black opacity-30"></div>
          <div
            style={{
              position: "absolute",
              top: "20px",
              left: "20px",
              color: "#fff",
              fontSize: "2vw",
              fontWeight: "bold",
              height: "auto",
              width: "auto",
            }}
          >
            WILLPOWER
          </div>
        </div>

        <div
          style={{
            position: "relative",
            backgroundColor: "#6BA4DF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={imgWisdom}
            alt="Wisdom"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              opacity: 0.8,
            }}
          />
          <div className="absolute inset-0 bg-black opacity-30"></div>
          <div
            style={{
              position: "absolute",
              top: "20px",
              left: "20px",
              color: "#fff",
              fontSize: "2vw",
              fontWeight: "bold",
              height: "auto",
              width: "auto",
            }}
          >
            WISDOM
          </div>
        </div>
      </div>
      {/* Grid */}
    </div>
  );
};

export default Home;
