import React, { useRef } from "react";
import Slider from "react-slick";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import { InforSection } from "../../../components";
import sectionsData from "../../../models/mockdata";

import "./Carousel.css";

export default function CarouselCustomNavigation({ id }) {
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "auto",
      }}
      className="responsive-carousel"
      id="aboutus"
    >
      <button
        style={{
          backgroundColor: "transparent",
          border: "none",
          fontSize: "3vw",
          cursor: "pointer",
          color: "#6BA4DF",
        }}
        className="responsive-button button-left"
        onClick={() => sliderRef.current.slickPrev()}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>

      {/* Slider */}
      <div className="responsive-slider">
        <Slider ref={sliderRef} {...settings}>
          {sectionsData.map((section, index) => (
            <InforSection
              key={index}
              title={section.title}
              content={section.content}
              imageSrc={section.imageSrc}
            />
          ))}
        </Slider>
      </div>

      <button
        style={{
          backgroundColor: "transparent",
          border: "none",
          fontSize: "3vw",
          cursor: "pointer",
          color: "#6BA4DF",
        }}
        className="responsive-button button-right"
        onClick={() => sliderRef.current.slickNext()}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );
}
