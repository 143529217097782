import "swiper/css";
import "swiper/css/pagination";

import banner from "../../assets/AU_Page/AboutUsBanner.png";

import missionimg from "../../assets/AU_Page/imgMission.png";

import visionimg1 from "../../assets/AU_Page/imgVision_1.png";
import visionimg2 from "../../assets/AU_Page/imgVision_2.png";
import visionimg3 from "../../assets/AU_Page/imgVision_3.png";
import visionimg4 from "../../assets/AU_Page/imgVision_4.png";

import identityimg from "../../assets/AU_Page/imgIdentity.png";
import purposeimg from "../../assets/AU_Page/imgPurpose.png";

import SwiperCustom from "./Swiper/Swiper";

const AboutUs = () => {
  return (
    <div>
      {/**Start banner */}
      <div className="relative flex justify-center items-center h-fit">
        <img src={banner} alt="" className="w-full h-[750px] object-cover" />

        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <span
            className="font-bold text-[6vw] md:text-[3vw] text-center py-14 h-fit"
            style={{ color: "white" }}
          >
            Our <span style={{ color: "#D23131" }}>Purpose</span>
          </span>
          <SwiperCustom />
          <div className="2xl:h-1/3 h-1/2"></div>
        </div>
      </div>
      {/**End banner */}

      {/**Start Body */}
      <div className="h-auto">
        {/**Start Mission */}
        <div className="grid md:grid-cols-2 grid-cols-1 h-fit pt-[6vw] pb-[3vw] md:gap-0 gap-8">
          <div className="flex flex-col justify-center md:px-[4vw] px-[2vw]">
            <span
              className="h-fit font-bold text-[6vw] md:text-[3vw]"
              style={{ color: "Black" }}
            >
              Our <span style={{ color: "#739E3D" }}>Mission</span>
            </span>
            <p className="text-[3vw] md:text-[1.25vw] h-fit">
              We are an exceptional investment firm in Vietnam with an all-win
              all-happiness philosophy where our clients, partners,
              shareholders, and communities all benefit as the results our the
              collective advance. Drawing from our deep experience working with
              the world's leading multinational corporations and entrepreneurs
              as well as leading local corporates and leaders, we mobilize our
              assets, technologies, ideas, and ecosystem to advance the
              collective success supported by our shared values of{" "}
              <b>
                {" "}
                good deeds - hard work - strong ethics - wisdom - willpower -
                harmonious - grateful
              </b>
            </p>
          </div>

          <div className="flex justify-center md:items-center">
            <img src={missionimg} alt="" className="object-cover w-full" />
          </div>
        </div>
        {/**End Mission */}

        {/**Start Vision */}
        <div className="grid md:grid-cols-2 grid-cols-1 w-full h-fit py-[3vw] md:gap-0 gap-4">
          <div className="flex flex-col items-center justify-between h-full">
            <div className="flex flex-row w-full justify-center items-end space-x-4 h-full">
              <img
                src={visionimg1}
                alt=""
                className="w-[50%] h-[65%] md:pr-[28px]"
              />
              <img
                src={visionimg2}
                alt=""
                className="w-[50%] h-full md:pr-[14px]"
              />
            </div>
          </div>

          <div className="flex flex-col md:pl-4 items-end h-full">
            <div className="h-fit md:justify-center px-4">
              <span
                className="h-[40%] font-bold text-[6vw] md:text-[3vw]"
                style={{ color: "Black" }}
              >
                Our <span style={{ color: "#B16D2D" }}>Vision</span>
              </span>
              <p className="text-[3vw] md:text-[1.25vw] h-fit">
                Together we build a better world, a better universe where
                happiness comes to all beings and things. A place where
                development is sustainable and nature is protected.
              </p>
            </div>

            <div className="flex flex-row w-full justify-center items-end space-x-4 mt-auto md:h-[60%] h-[70%]">
              <img
                src={visionimg3}
                alt=""
                className="w-[50%] h-[100%] md:pl-[14px]"
              />
              <img
                src={visionimg4}
                alt=""
                className="w-[50%] h-[100%] md:pl-[28px]"
              />
            </div>
          </div>
        </div>
        {/**End Vision */}

        {/**Start Out Identity */}
        <div className="grid md:grid-cols-[2fr_3fr] grid-cols-1 h-fit pt-[3vw] pb-[6vw]">
          <div className="flex justify-center md:items-center">
            <img
              src={identityimg}
              alt=""
              className="object-cover w-full h-fit py-[4vw] md:py-0"
            />
          </div>

          <div className="flex flex-col justify-center p-4 md:px-[4vw] px-[2vw] md:py-0">
            <span
              class="h-fit font-bold text-[6vw] md:text-[4vw]"
              style={{ color: "Black" }}
            >
              Who we are -{" "}
              <span style={{ color: "#3C2EE1" }}>Our Identity</span>
            </span>
            <p className="text-[3vw] md:text-[1.25vw] h-fit">
              Hoang Thanh Group is an investment holding company with M&A and
              principle investments in financial institutions as our core
              businesses. Through our group and portfolio companies our
              activities also expand to digital solutions investment and
              development, real estate and hospitality, warehousing & logistics,
              architect & interior designs. Our senior management team have
              combine decades of investment banking experiences in Vietnam with
              a solid track record. The management team is supported by a team
              of senior industry experts both local and international, each with
              many years of experience and deep knowledge in their own field.
              Drawing from our deep experience working with the world's leading
              multinational corporations and entrepreneurs and driven by our
              shared DNA values, Hoang Thanh Group mobilize our assets,
              technologies, ideas, and ecosystem to advance the success of the
              collective with the goal of accelerating economic progress and
              strength bonds between Vietnam and the international communities.
            </p>
          </div>
        </div>
        {/**End Out Identity */}

        <div className="relative h-fit">
          <img src={purposeimg} alt="" className="w-full  object-cover z-0" />
          <div className="absolute md:inset-20 inset-5 h-fit w-fit flex flex-col z-10 lg:space-y-2 space-y-1">
            <span
              className="h-fit font-bold text-[6vw] md:text-[3vw]"
              style={{ color: "white" }}
            >
              Our <span style={{ color: "#D23131" }}>Purpose</span>
            </span>
            <p className="text-[3vw] md:text-[1.5vw] h-fit text-white w-[90%] md:w-[45%]">
              Together we build a better world, a better universe Where
              happiness comes to all beings and things. A place where
              development is sustainable and nature is protected.
            </p>
          </div>
        </div>
      </div>
      {/**End Body */}
    </div>
  );
};

export default AboutUs;
