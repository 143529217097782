import React, { useState, forwardRef, useEffect } from "react";
import logo from "../../assets/logoHT.svg";
import { path } from "../../utils/constants";
import { useNavigate, useLocation } from "react-router-dom";
import imgMenuclose from "../../assets/menu/bt2.svg";
import imgMenuopen from "../../assets/menu/menuIcon.svg";
import { Icon } from "@iconify/react/dist/iconify.js";
const Header = forwardRef((props, ref) => {
  const [sidebar, setSidebar] = useState(false);

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  const location = useLocation();
  const navigate = useNavigate();

  const scrollToFooter = () => {
    navigate(path.Landing);

    setTimeout(() => {
      const footer = document.getElementById("footer");
      if (footer) {
        footer.scrollIntoView({ behavior: "smooth" });
      }
    });
  };
  const scrollToAboutUs = () => {
    navigate(path.Landing);

    setTimeout(() => {
      const aboutus = document.getElementById("aboutus");
      if (aboutus) {
        aboutus.scrollIntoView({ behavior: "smooth" });
      }
    });
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setSidebar(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  console.log("location >>>", location.pathname);
  console.log("path >>>", path.Landing);
  console.log(location.pathname === path.Landing);

  return (
    <header
      ref={ref}
      className="relative z-2 py-2 flex  items-center h-fit mx-auto md:w-[100%]  pl-[10%] pv:max-md:pr-[10%]"
    >
      <img
        onClick={() => {
          navigate("../" + path.Landing);
        }}
        className=" w-[80px] pv:max-sm:w-[40px] pv:max-sm:h-[40px] h-full object-cover border-[1px] border-white  cursor-pointer"
        src={logo}
        alt="logo"
      />
      <nav className=" ">
        {/* Desktop Menu */}
        <ul className="hidden md:flex  gap-40 md:max-xl:gap-20  items-center justify-center font-semibold">
          <li className="w-fit m-0 h-fit">
            <a
              onClick={() => {
                navigate("../" + path.Landing);
              }}
              className={`hover:pb-[12px] hover:border-b-[3px] hover:border-sky-400 cursor-pointer 
                ${
                  location.pathname === path.Landing
                    ? "pb-[12px] border-b-[3px] border-sky-300"
                    : ""
                }`}
            >
              HOME
            </a>
          </li>
          <li className="w-fit m-0 h-fit">
            <a
              onClick={() => {
                scrollToAboutUs();
              }}
              className={`hover:pb-[12px] hover:border-b-[3px] hover:border-sky-400 cursor-pointer`}
            >
              ABOUT US
            </a>
          </li>
          <li className="w-fit m-0 h-fit">
            <a
              onClick={() => {
                scrollToFooter();
              }}
              className={`hover:pb-[12px] hover:border-b-[3px] hover:border-sky-400 cursor-pointer`}
            >
              CONTACT US
            </a>
          </li>
          <li className="w-fit m-0 h-fit">
            <a
              onClick={() => {
                navigate("../" + path.Activities);
              }}
              className={`hover:pb-[12px] hover:border-b-[3px] hover:border-sky-400 cursor-pointer
                ${
                  location.pathname === path.Activities
                    ? "pb-[12px] border-b-[3px] border-sky-300"
                    : ""
                }`}
            >
              ACTIVITIES
            </a>
          </li>
        </ul>

        {/* Mobile Menu Icon */}
        <div
          className="md:hidden cursor-pointer flex justify-end"
          onClick={toggleSidebar}
        >
          {sidebar ? (
            // <img
            //   className="w-[24px] h-[24px]"
            //   src={imgMenuopen}
            // ></img>
            <Icon icon={"ic:round-close"} className="w-[24px] h-[24px]" />
          ) : (
            // <img
            //   className="w-[24px] h-[24px]"
            //   src={imgMenuclose}
            // ></img>
            <Icon
              icon={"flowbite:bars-outline"}
              className="w-[24px] h-[24px]"
            />
          )}
        </div>

        {/* Mobile Sidebar */}
        <div
          className={
            "z-5 absolute bg-white shadow-[0px_5px_89.61px_13.39px_rgba(23,29,113,0.05)] top-[15vw] right-0 w-auto flex flex-col items-end overflow-hidden transition-transform duration-300 ease-in-out h-auto rounded-lg"
          }
          style={{
            minHeight: "fit-content",
            opacity: sidebar ? 1 : 0,
            visibility: sidebar ? "visible" : "hidden",
            transform: sidebar ? "translateY(0)" : "translateY(-20px)",
            zIndex: sidebar ? 999 : -1,
          }}
        >
          <ul className="flex flex-col gap-6 font-semibold p-4 pt-4">
            <li className="h-fit">
              <a
                onClick={() => {
                  navigate(".." + path.Landing);
                  setSidebar(false);
                }}
                className={`hover:text-sky-700 
                ${
                  location.pathname === path.Landing
                    ? "text-sky-500"
                    : "text-black"
                }`}
              >
                HOME
              </a>
            </li>
            <li className="h-fit">
              <a
                onClick={() => {
                  scrollToAboutUs();
                  setSidebar(false);
                }}
                className="hover:text-sky-700 text-black"
              >
                ABOUT US
              </a>
            </li>
            <li className="h-fit">
              <a
                onClick={() => {
                  scrollToFooter();
                  setSidebar(false);
                }}
                className="hover:text-sky-700 text-black"
              >
                CONTACT US
              </a>
            </li>
            <li className="h-fit">
              <a
                onClick={() => {
                  navigate(".." + path.Activities);
                  setSidebar(false);
                }}
                className={`hover:text-sky-700
                  ${
                    location.pathname === path.Activities
                      ? "text-sky-500"
                      : "text-black"
                  }`}
              >
                ACTIVITIES
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
});

export default Header;
