import imgCulture from "../assets/carousel_img/Culture.png";
import imgAccountability from "../assets/carousel_img/our_culture_Accounttability_1.jpg";
import imgAdaptability from "../assets/carousel_img/v2_OurcultureAdaptability_1.jpg";
import imgCollaboration from "../assets/carousel_img/Collaboration.jpg";
import imgCompassion from "../assets/carousel_img/our_culture_ Compassion_1.jpg";
import imgDiligence from "../assets/carousel_img/v2_OurcultureDiligence_1.jpg";
import imgFun from "../assets/carousel_img/v2_OurcultureFun_1.jpg";
import imgInnovation from "../assets/carousel_img/INNOVATION_1.jpg";
import imgSelfDevelopment from "../assets/carousel_img/our_culture_Self_Development_1.jpg";
import imgTransparency from "../assets/carousel_img/Our_culture_Transparency_1.jpg";
import imgWellBeing from "../assets/carousel_img/v2_OurcultureWell-being_1.jpg";
import imgWillpower from "../assets/carousel_img/Our_culture_Willpower_1.jpg";
import imgWisdom from "../assets/carousel_img/Our_culture_Wisdom_1.jpg";
import imgAboutUs from "../assets/carousel_img/aboutus_thumbanil.png";

const sectionsData = [
  {
    title: "ABOUT US",
    content: (
      <>
        Hoang Thanh Group is an investment holding company with M&A and principal investments as our core businesses.
        <br />
        <br />
        Through our portfolio companies our activities also extend to digital solutions consultancy & development, wholesale end-to-end logistics, real estate brokerage and development, hospitality, and architect & interior designs.
      </>
    ),
    imageSrc: imgAboutUs,
  },
  {
    title: "OUR CULTURE",
    content: null,
    imageSrc: imgCulture,
  },
  {
    title: "ACCOUNTABILITY",
    content: (
      <>
        Create an environment where open dialogue is encouraged where regular
        feedback, transparent discussions, and active listening help reinforce
        accountability.
        <br />
        <br />
        Promotes a culture of accountability where employees take ownership of
        their work, deliver results with excellence, and hold themselves and
        others to high standards of performance.
        <br />
        <br />
        Encourages proactive problem-solving, initiative, and a sense of
        personal responsibility for the company's success.
      </>
    ),
    imageSrc: imgAccountability,
  },
  {
    title: "ADAPTABILITY",
    content: (
      <>
        Encourages flexibility, resilience, and a willingness to embrace change
        as opportunities for growth and innovation.
        <br />
        <br />
        We believe the best solutions come from diverse perspectives. We
        encourage experimentation, brainstorming, and embracing new ways of
        thinking.
      </>
    ),
    imageSrc: imgAdaptability,
  },
  {
    title: "COLLABORATION",
    content: (
      <>
        Fosters a collaborative work environment where teamwork, communication,
        and mutual respect are valued.
        <br />
        <br />
        Together we're stronger - we achieve more through collaboration.
        <br />
        <br />
        Great ideas spark from teamwork - we collaborate to innovate.
        <br />
        <br />
        We value individual expertise, but success comes through collaboration.
        <br />
        <br />
        Shared goals unite us - we collaborate to achieve the best possible
        outcome.
      </>
    ),
    imageSrc: imgCollaboration,
  },
  {
    title: "COMPASSION",
    content: (
      <>
        Foster a compassionate environment to create a workplace where everyone
        feels valued, understood, and uplifted.
        <br />
        <br />
        Genuinely care for one another, recognizing that each person faces
        unique challenges.
        <br />
        <br />
        Actively listen, empathize, and support our colleagues during both
        professional and personal moments.
      </>
    ),
    imageSrc: imgCompassion,
  },
  {
    title: "DILIGENCE",
    content: (
      <>
        Foster a culture of careful consideration and thoroughness, we set high
        standards and embrace meticulous attention to detail.
        <br />
        <br />
        Diligence is our compass, guiding us toward quality and excellence. We
        leave no stone unturned in our pursuit of perfection.
        <br />
        <br />
        Our commitment to diligence ensures consistent, reliable, and excellence
        outcomes.
      </>
    ),
    imageSrc: imgDiligence,
  },
  {
    title: "FUN",
    content: (
      <>
        Foster a positive and engaging work environment by incorporating
        elements of fun, camaraderie, and celebration into the workplace.
        <br />
        <br />
        Encourages and organizes team-building activities, social events, and
        recognition programs to celebrate achievements, milestones, and
        successes together.
        <br />
        <br />
        Everyone shall live an excellent life.
      </>
    ),
    imageSrc: imgFun,
  },
  {
    title: "INNOVATION",
    content: (
      <>
        Encourages a culture of innovation where employees are empowered to
        think creatively, challenge the status quo, and propose new ideas to
        improve products, processes, and services.
        <br />
        <br />
        Values entrepreneurial spirit, risk-taking, and experimentation to drive
        continuous improvement and stay ahead in the competitive environment.
        <br />
        <br />
        Foster an environment where creativity thrives, risks are taken, and
        breakthroughs happen.
      </>
    ),
    imageSrc: imgInnovation,
  },
  {
    title: "SELF-DEVELOPMENT",
    content: (
      <>
        Encourage self learning and development initiatives to foster continuous
        growth, skill-building, and career advancement opportunities.
        <br />
        <br />
        Provides access to training programs, mentorship, and resources to help
        employees develop new skills, expand their knowledge, and achieve their
        professional goals.
        <br />
        <br />
        Foster a strong self learning and development culture not only enhances
        individual capabilities but also contributes to the overall success of
        the organization.
      </>
    ),
    imageSrc: imgSelfDevelopment,
  },
  {
    title: "TRANSPARENCY",
    content: (
      <>
        Encourages open and honest communication at all levels of the
        organization, enabling employees to voice their opinions, concerns, and
        ideas without fear of reprisal.
        <br />
        <br />
        We celebrate open communication and information sharing, fostering a
        culture of trust.
        <br />
        <br />
        We embrace honest feedback, both positive and negative, to continuously
        improve through transparency.
      </>
    ),
    imageSrc: imgTransparency,
  },
  {
    title: "WELL-BEING",
    content: (
      <>
        Foster a culture that prioritizes eat clean, drink clean, breath clean,
        brain clean, work clean as a guiding compass to maintaining a healthy
        and sustainable lifestyle.
        <br />
        <br />
        Everyone should live an excellent life.
        <br />
        <br />
        Encourage flexible work arrangements, wellness programs, and initiatives
        to promote physical, mental, and emotional health among employees.
      </>
    ),
    imageSrc: imgWellBeing,
  },
  {
    title: "WILLPOWER",
    content: (
      <>
        Foster a culture that embrace challenges with unwavering willpower,
        achieving the seemingly impossible.
        <br />
        <br />
        We believe that self-driven individuals not only achieve personal
        success but also elevate the entire organization.
        <br />
        <br />
        Difficulties do not deter us - they ignite our willpower to succeed. We
        believe willpower can transform ideas into reality.
      </>
    ),
    imageSrc: imgWillpower,
  },
  {
    title: "WISDOM",
    content: (
      <>
        Prioritize well-considered choices based on experience, knowledge, and a
        clear understanding of potential consequences.
        <br />
        <br />
        Encourage employees to actively learn from successes and failures, both
        our own and those of others and develop the ability to assess situations
        accurately and make insightful decisions.
        <br />
        <br />
        Encourage balanced perspective considering all sides of an issue and
        approaching challenges with a long-term view to make informed choices.
      </>
    ),
    imageSrc: imgWisdom,
  },
];

export default sectionsData;
