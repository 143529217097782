import React, { useEffect, useState, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom";

import imgBanner from "../../assets/HT_Banner.png";

import imgMA from "../../assets/raw_img/ActivityMA_1.jpg";
import imgPrincipal from "../../assets/raw_img/Principal_1.jpg";
import imgRealEstate from "../../assets/raw_img/RealEstate1.jpg";
import imgRetail from "../../assets/raw_img/ActivityRetailandtrading.jpg";
import imgArchitech from "../../assets/raw_img/Architech.jpg";

import sacredgeo_1 from "../../assets/scaredGeo/item1.svg";
import sacredgeo_2 from "../../assets/scaredGeo/item2.svg";
import sacredgeo_3 from "../../assets/scaredGeo/item3.svg";
import sacredgeo_4 from "../../assets/scaredGeo/item4.svg";

import DigitalSolution from "./SectionDigitalSolution";
import { Header } from "../../components";

const Activities = () => {
  const headerRef = useRef(null);
  const [ref_m_a, inViewM_A] = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });

  const [ref_invest, inViewInvest] = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });

  const [ref_retail_title, inViewRetailTitle] = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });
  const [ref_retail_content, inViewRetailContent] = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });

  const [ref_real_estate_title, inViewRealEstateTitle] = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });
  const [ref_real_estate_content, inViewRealEstateContent] = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });

  const [ref_1, inView1] = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });
  const [ref_2, inView2] = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });
  const [ref_3, inView3] = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });
  const [ref_4, inView4] = useInView({
    threshold: 0.4,
    triggerOnce: false,
  });

  // Ref img
  const [ref_imgMA, inViewimgMA] = useInView({
    threshold: 0.4,
    triggerOnce: false,
  });

  const [ref_imgPri, inViewimgPri] = useInView({
    threshold: 0.4,
    triggerOnce: false,
  });

  const [ref_imgRetail, inViewimgRetail] = useInView({
    threshold: 0.4,
    triggerOnce: false,
  });

  const [ref_imgReal, inViewimgReal] = useInView({
    threshold: 0.4,
    triggerOnce: false,
  });

  const variants_img_1 = {
    visible: { x: 0, y: 0 },
    hidden: {
      x: -200,
      y: -200,
    },
  };

  const variants_img_2 = {
    visible: { x: 0, y: 0 },
    hidden: {
      x: 0,
      y: -200,
    },
  };

  const variants_img_3 = {
    visible: { x: 0, y: 0 },
    hidden: {
      x: -200,
      y: -200,
    },
  };

  const variants_img_4 = {
    visible: { x: 0, y: 0 },
    hidden: {
      x: 200,
      y: -200,
    },
  };

  const variants_title = {
    visible: { opacity: 1, x: 0 },
    hidden: {
      opacity: 0,
      x: -20,
    },
  };

  const variants_description = {
    visible: { opacity: 1, y: 0 },
    hidden: {
      opacity: 0,
      y: -30,
    },
  };

  const variants_img_MA = {
    visible: { scale: 1 },
    hidden: {
      scale: 0.95,
    },
  };

  const variants_img_Pri = {
    visible: { scale: 1 },
    hidden: {
      scale: 0.95,
    },
  };

  const variants_img_Retail = {
    visible: { scale: 1 },
    hidden: {
      scale: 0.95,
    },
  };

  const variants_img_Real = {
    visible: { scale: 1 },
    hidden: {
      scale: 0.95,
    },
  };

  const [isGreenClicked, setIsGreenClicked] = useState(false);

  const handleGreenButtonClick = () => {
    setIsGreenClicked(!isGreenClicked);
  };

  const buttonMoveVariants = {
    initial: { x: 0 },
    moveLeft: { x: -10 },
    moveRight: { x: 10 },
  };

  const location = useLocation();
  useEffect(() => {
    // Check if the location contains a fragment identifier
    const fragment = location.hash;
    if (fragment) {
      // Extract the section id from the fragment
      const sectionId = fragment.substring(1);
      // Scroll to the section with the corresponding id
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      <Header ref={headerRef} />

      <div className="flex flex-col">
        <motion.div
          id="removeLandingBanner"
          className="relative pv:max-ph:h-[50vh] xl:h-[85vh] lg:h-[70vh] md:h-[50vh] h-[40vh] pv:max-ph:pb-4 pb-20"
        >
          <img
            className="w-full h-full object-cover object-bottom z-[-1] "
            src={imgBanner}
            alt="banner"
          />
          <motion.p
            className="font-privateFont font-bold text-white 2xl:text-[72px] lg:text-5xl md:text-4xl text-2xl absolute top-[40%] left-[50%] w-[95%] text-center z-[1] p_Landing_Title h-fit"
            style={{ transform: "translate(-50%)" }}
          >
            HOANG THANH CAPITAL & PARTNERS
          </motion.p>
        </motion.div>

        <div
          id="activities_content"
          className="lg:grid grid-cols-2 mx-auto lg:w-[70%] gap-10 pv:max-ph:grid-cols-1 pv:max-ph:w-[90%] ph:max-sm:grid-cols-1 ph:max-sm:w-[70%] sm:max-md:grid-cols-2 sm:max-md:w-[95%] md:max-lg:grid-cols-2 md:max-lg:w-[80%] lg:max-xl:grid-cols-2 lg:max-xl:w-[80%] xl:max-dv:grid-cols-2 xl:max-dv:w-[80%] !h-fit pb-20"
        >
          {/* Section M&A */}
          <motion.div className="!h-fit flex flex-col-reverse md:block">
            <div className="overflow-hidden h-[400px] ease-in-out w-full rounded-md pv:max-md:h-[300px] md:max-lg:h-[400px] cursor-pointer">
              <div className="hover:scale-105 duration-300">
                <motion.img
                  src={imgMA}
                  className="object-cover rounded-md w-full h-[400px]"
                  animate={inViewimgMA ? "visible" : "hidden"}
                  variants={variants_img_MA}
                  transition={{ duration: 0.75, ease: "easeOut" }}
                  ref={ref_imgMA}
                ></motion.img>
              </div>
            </div>

            <div className="relative h-fit">
              <motion.p
                animate={inViewM_A ? "visible" : "hidden"}
                variants={variants_title}
                transition={{ duration: 0.75, ease: "easeOut" }}
                ref={ref_m_a}
                className="py-10 pb-3 text-[28px] lg:text-[36px] font-semibold text-[#333333] cursor-pointer h-fit"
              >
                M&A
              </motion.p>

              <motion.p
                animate={inViewM_A ? "visible" : "hidden"}
                variants={variants_description}
                transition={{ duration: 0.75, ease: "easeOut" }}
                ref={ref_m_a}
                className="text-[18px] text-[#333333] leading-10 h-fit"
              >
                Hoang Thanh Group has a solid track record and strong market
                reputation with decades of combined investment banking
                experience in Vietnam. Drawing from our deep experiences working
                with Governmental institutions, multinational corporations,
                global financial institutions and top Vietnamese corporations,
                we aim to provide a unique investment banking services
                experience to clients that share our core values.
              </motion.p>

              <motion.div
                ref={ref_1}
                animate={inView1 ? "visible" : "hidden"}
                variants={variants_img_1}
                transition={{ duration: 1.75, ease: "easeInOut" }}
                className="absolute w-[70%] h-[70%] ph:max-sm:h-[350px] ph:max-sm:w-[350px] top-[-10%] left-[-40%] z-[-100] md:max-lg:left-0 md:max-lg:top-[40%] sm:max-md:left-0 sm:max-md:top-[40%] pv:max-ph:top-[10%] pv:max-ph:left-[10%] opacity-40"
              >
                <img className="" src={sacredgeo_1} alt="scaredGeo"></img>
              </motion.div>
            </div>
          </motion.div>

          {/* Section Pricipal Investments */}
          <motion.div className="!h-fit flex flex-col-reverse md:block">
            <div className="overflow-hidden h-[400px] ease-in-out w-full rounded-md pv:max-md:h-[300px] md:max-lg:h-[400px] cursor-pointer">
              <div className="hover:scale-105 duration-300">
                <motion.img
                  src={imgPrincipal}
                  className="object-cover rounded-md w-full h-[400px]"
                  animate={inViewimgPri ? "visible" : "hidden"}
                  variants={variants_img_Pri}
                  transition={{ duration: 0.75, ease: "easeOut" }}
                  ref={ref_imgPri}
                ></motion.img>
              </div>
            </div>

            <div className="relative h-fit">
              <motion.p
                animate={inViewInvest ? "visible" : "hidden"}
                variants={variants_title}
                transition={{ duration: 0.75, ease: "easeOut" }}
                ref={ref_invest}
                className="py-10 pb-3 text-[28px] lg:text-[36px] font-semibold text-[#333333] cursor-pointer h-fit"
              >
                PRINCIPAL INVESTMENTS
              </motion.p>

              <div className="relative">
                <motion.p
                  animate={inViewInvest ? "visible" : "hidden"}
                  variants={variants_description}
                  transition={{ duration: 0.75, ease: "easeOut" }}
                  ref={ref_invest}
                  className="text-[18px] text-[#333333] leading-10 h-fit"
                >
                  Hoang Thanh Group has equity investments in financial institutions, distribution, hospitality, digital technology companies that have shared core values.
                </motion.p>

                <motion.div
                  ref={ref_2}
                  animate={inView2 ? "visible" : "hidden"}
                  variants={variants_img_2}
                  transition={{ duration: 1.5, ease: "easeOut" }}
                  className="absolute w-[70%] h-[70%] ph:max-sm:h-[350px] ph:max-sm:w-[350px] top-[-10%] left-[50%]  z-[-100] md:max-lg:left-0 md:max-lg:top-[40%] sm:max-md:left-0 sm:max-md:top-[40%] pv:max-ph:top-[10%] pv:max-ph:left-[10%] "
                >
                  <img
                    className="opacity-50"
                    src={sacredgeo_2}
                    alt="scaredGeo"
                  ></img>
                </motion.div>
              </div>
            </div>
          </motion.div>
        </div>

        <DigitalSolution />

        <div className="lg:grid grid-cols-2 mx-auto w-[80%] lg:w-[70%] gap-10 pv:max-ph:grid-cols-1  pv:max-ph:w-[90%] ph:max-sm:grid-cols-1 ph:max-sm:w-[70%] sm:max-md:grid-cols-2 sm:max-md:w-[95%] md:max-lg:grid-cols-2 md:max-lg:w-[80%] lg:max-xl:grid-cols-2 lg:max-xl:w-[80%] xl:max-dv:grid-cols-2 xl:max-dv:w-[80%] py-[2vw]">
          {/* Section Retail */}
          {/* <motion.div className="!h-fit">
            <div className="overflow-hidden h-[400px] ease-in-out w-full rounded-md pv:max-md:h-[300px] md:max-lg:h-[400px] cursor-pointer">
              <div className="hover:scale-105 duration-300">
                <motion.img
                  src={imgRetail}
                  className="object-cover rounded-md w-full h-[400px]"
                  animate={inViewimgRetail ? "visible" : "hidden"}
                  variants={variants_img_Retail}
                  transition={{ duration: 0.75, ease: "easeOut" }}
                  ref={ref_imgRetail}
                ></motion.img>
              </div>
            </div>

            <div className="relative h-fit">
              <motion.p
                animate={inViewRetailTitle ? "visible" : "hidden"}
                variants={variants_title}
                transition={{ duration: 0.75, ease: "easeOut" }}
                ref={ref_retail_title}
                className="py-10 pb-3 text-[28px] lg:text-[36px] font-semibold text-[#333333] cursor-pointer h-fit"
              >
                RETAIL TRADING
              </motion.p>
              <motion.p
                animate={inViewRetailContent ? "visible" : "hidden"}
                variants={variants_description}
                transition={{ duration: 0.75, ease: "easeOut" }}
                ref={ref_retail_content}
                className="text-[18px] text-[#333333] leading-10 h-fit"
              >
                Through our portfolio company, Hoang Thanh Group is active in
                retail trading offering services such as end-to-end B2B or B2C
                distribution, delivering products to Vietnamese consumers
                through a value chain of planning, organizing, marketing,
                warehousing and delivery.
              </motion.p>

              <motion.div
                ref={ref_3}
                animate={inView3 ? "visible" : "hidden"}
                variants={variants_img_3}
                transition={{ duration: 1.75, ease: "easeInOut" }}
                className="absolute w-[50%] h-[50%] ph:max-sm:h-[350px] ph:max-sm:w-[350px] top-[-10%] left-[-40%] z-[-100] md:max-lg:left-0 md:max-lg:top-[40%] sm:max-md:left-0 sm:max-md:top-[40%] pv:max-ph:top-[10%] pv:max-ph:left-[10%] opacity-40"
              >
                <img className="" src={sacredgeo_3} alt="scaredGeo"></img>
              </motion.div>
            </div>
          </motion.div> */}

          {/* Section Real Estate */}
          <motion.div className="justify-between h-full">
            <div className="flex flex-col-reverse md:block">
              <div className="overflow-hidden h-[400px] ease-in-out w-full rounded-md pv:max-md:h-[300px] md:max-lg:h-[400px] cursor-pointer">
                <div className="hover:scale-105 duration-300">
                  <motion.img
                    src={isGreenClicked ? imgArchitech : imgRealEstate}
                    className="object-cover rounded-md w-full h-[400px]"
                    animate={inViewimgReal ? "visible" : "hidden"}
                    variants={variants_img_Real}
                    transition={{ duration: 0.75, ease: "easeOut" }}
                    ref={ref_imgReal}
                  ></motion.img>
                </div>
              </div>

              <div className="relative flex flex-col h-fit">
                <motion.p
                  animate={inViewRealEstateTitle ? "visible" : "hidden"}
                  variants={variants_title}
                  transition={{ duration: 0.75, ease: "easeOut" }}
                  ref={ref_real_estate_title}
                  className="py-10 pb-3 text-[28px] lg:text-[36px] font-semibold text-[#333333] cursor-pointer h-fit"
                >
                  {isGreenClicked
                    ? "ARCHITECH & INTERIOR DESIGNS"
                    : "REAL ESTATE & HOSPITALITY"}
                </motion.p>

                <motion.p
                  animate={inViewRealEstateContent ? "visible" : "hidden"}
                  variants={variants_description}
                  transition={{ duration: 0.75, ease: "easeOut" }}
                  ref={ref_real_estate_content}
                  className="text-[18px] text-[#333333] leading-10 flex-grow"
                >
                  {isGreenClicked
                    ? "Hoang Thanh Group also own an architect and interior design subsidiary with its philosophy centered around eat clean, breath clean, brain clean, work clean and live clean."
                    : "Hoang Thanh Group invests in a number of mixed-use luxury office buildings and hotels in Hanoi and HCMC, and has significant investment in a real estate brokerage and development company."}
                </motion.p>

                <motion.div
                  ref={ref_3}
                  animate={inView3 ? "visible" : "hidden"}
                  variants={variants_img_3}
                  transition={{ duration: 1.75, ease: "easeInOut" }}
                  className="absolute w-[50%] h-[50%] ph:max-sm:h-[350px] ph:max-sm:w-[350px] top-[-10%] left-[-40%] z-[-100] md:max-lg:left-0 md:max-lg:top-[40%] sm:max-md:left-0 sm:max-md:top-[40%] pv:max-ph:top-[10%] pv:max-ph:left-[10%] opacity-40"
                >
                  <img className="" src={sacredgeo_3} alt="scaredGeo"></img>
                </motion.div>
              </div>
            </div>

            {/* Buttons */}
            {/* <div className="flex justify-center items-center h-fit">
              <AnimatePresence>
                {isGreenClicked ? (
                  <>
                    <motion.button
                      key="black-button"
                      onClick={handleGreenButtonClick}
                      initial="initial"
                      animate="moveLeft"
                      exit="exit"
                      variants={buttonMoveVariants}
                      transition={{ duration: 0.5, ease: "easeOut" }}
                      className="w-5 h-5 bg-gray-400 rounded-full"
                    />
                    <motion.button
                      key="green-button"
                      onClick={handleGreenButtonClick}
                      initial="initial"
                      animate="moveRight"
                      exit="exit"
                      variants={buttonMoveVariants}
                      transition={{ duration: 0.5, ease: "easeOut" }}
                      className="w-5 h-5 bg-[#6da4da] rounded-full"
                    />
                  </>
                ) : (
                  <>
                    <motion.button
                      key="green-button"
                      onClick={handleGreenButtonClick}
                      initial="initial"
                      animate="moveLeft"
                      exit="exit"
                      variants={buttonMoveVariants}
                      transition={{ duration: 0.5, ease: "easeOut" }}
                      className="w-5 h-5 bg-[#6da4da] rounded-full"
                    />
                    <motion.button
                      key="black-button"
                      onClick={handleGreenButtonClick}
                      initial="initial"
                      animate="moveRight"
                      exit="exit"
                      variants={buttonMoveVariants}
                      transition={{ duration: 0.5, ease: "easeOut" }}
                      className="w-5 h-5 bg-gray-400  rounded-full"
                    />
                  </>
                )}
              </AnimatePresence>
            </div> */}
          </motion.div>

          <motion.div className="!h-fit flex flex-col-reverse md:block">
            <div className="overflow-hidden h-[400px] ease-in-out w-full rounded-md pv:max-md:h-[300px] md:max-lg:h-[400px] cursor-pointer">
              <div className="hover:scale-105 duration-300">
                <motion.img
                  src={imgArchitech}
                  className="object-cover rounded-md w-full h-[400px]"
                  animate={inViewimgRetail ? "visible" : "hidden"}
                  variants={variants_img_Retail}
                  transition={{ duration: 0.75, ease: "easeOut" }}
                  ref={ref_imgReal}
                ></motion.img>
              </div>
            </div>

            <div className="relative h-fit">
              <motion.p
                animate={inViewRetailTitle ? "visible" : "hidden"}
                variants={variants_title}
                transition={{ duration: 0.75, ease: "easeOut" }}
                ref={ref_retail_title}
                className="py-10 pb-3 text-[28px] lg:text-[36px] font-semibold text-[#333333] cursor-pointer h-fit"
              >
                ARCHITECH & INTERIOR DESIGNS
              </motion.p>
              <motion.p
                animate={inViewRetailContent ? "visible" : "hidden"}
                variants={variants_description}
                transition={{ duration: 0.75, ease: "easeOut" }}
                ref={ref_retail_content}
                className="text-[18px] text-[#333333] leading-10 h-fit"
              >
                Hoang Thanh Group also own an architect and interior design subsidiary with its philosophy centered around eat clean, breath clean, brain clean, work clean and live clean.
              </motion.p>

              <motion.div
                ref={ref_4}
                animate={inView4 ? "visible" : "hidden"}
                variants={variants_img_4}
                transition={{ duration: 1.75, ease: "easeInOut" }}
                className="absolute w-[40%] h-[40%] ph:max-sm:h-[350px] ph:max-sm:w-[350px] top-[10%] left-[50%] z-[-100] md:max-lg:left-0 md:max-lg:top-[40%] sm:max-md:left-0 sm:max-md:top-[40%] pv:max-ph:top-[10%] pv:max-ph:left-[10%] opacity-40 h-fit"
              >
                <img className="" src={sacredgeo_4} alt="scaredGeo"></img>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Activities;
