import "./InforSection.css";

const InfoSection = ({ title, content, imageSrc }) => {
  return (
    <div>
      <div className="info-section  ">
        <div
          className=" h-[40vw] pv:max-phm:h-[55vw]"
          style={
            title === "OUR CULTURE"
              ? {
                  backgroundColor: "#6BA4DF",
                  color: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : {
                  backgroundColor: "#6BA4DF",
                  color: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px",
                }
          }
        >
          <p
            style={
              title === "OUR CULTURE"
                ? {
                    height: "auto",
                    fontSize: "2.5vw",
                    fontWeight: "bold",
                    textAlign: "center",
                  }
                : {
                    height: "auto",
                    fontWeight: "bold",
                    margin: "0 0 10px 0",
                  }
            }
            className="title"
          >
            {title}
          </p>

          <p
            style={{
              height: "auto",
              margin: "0",
            }}
            className="content"
          >
            {content}
          </p>
        </div>

        <div
          style={{
            backgroundColor: "#e0e0e0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={imageSrc}
            alt={title}
            className="object-cover h-[40vw] pv:max-phm:h-[55vw]"
            style={{
              width: "100%",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
