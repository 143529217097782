import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = ({ headerRef }) => {
  const { pathname } = useLocation();

  // useEffect(() => {
  //   if (pathname === '/activities' || pathname === '/aboutus') {
  //     headerRef.current.scrollIntoView({ behavior: 'smooth' });
  //   } else {
  //     window.scrollTo(0, 0);
  //   }
  // }, [pathname, headerRef]);

  return null;
};

export default ScrollToTop;
